import React from "react";
import "./catalogo.css";

const Catalogo = () => {

  return (
    <div className="catalogo">
        <div className="catalogoContenido">
        <h1>Conozca nuestro CATALOGO 2024</h1>
        <a href="https://drive.google.com/uc?export=download&id=1rDpHzdNk0r6_J0l4uY1DyqE0A3cYZKla"><button className="btn">Descargue ahora!</button></a>
        </div>
    </div>
  );
};

export default Catalogo;