import React from "react";
import "./footer.css";

const Footer = (props) => {
  return (
    <div className="footer">
        <p> ©BYD SUDAMERICANA S.A.. All Rights Reserved.</p>
    </div>
  );
};

export default Footer;