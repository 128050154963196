import uno from "./carrousel/1.webp"
import dos from "./carrousel/2.webp"
import tres from "./carrousel/3.webp"
import cuatro from "./carrousel/4.webp"
import cinco from "./carrousel/5.webp"


export const imagesCarrousel = [
  {
    id: 1,
    img: uno,
  },
  {
    id: 2,
    img: dos,
  },
  {
    id: 3,
    img: tres,
  },
  {
    id: 4,
    img: cuatro,
  },
  {
    id: 5,
    img: cinco,
  }
];
